#products{
	
	p{
// 		text-align: justify;
	}
	.flx_products{
		@include flxBasic();
		>div{
			width: 53%;
			min-width: calc(100% - 200px);
		}
		>img{
			width: 45%;
			max-width: 180px;
		}
		@include maxW('max-mbl'){
			align-items: center;
			>div{
				width: 58%;
			}
			>img{
				width: 40%;
			}
		}
	}

}