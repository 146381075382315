@charset "utf-8";

@import "variables";

/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
@import "opinion";

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */

body{
	background: $siteCol-white;
}

.inner{
	width: 970px;
	max-width: calc(100% - 40px);
	margin: auto;
}

main{
	background: $siteCol-white;
	border: {
		top: 20px solid $siteCol-water;
		bottom: 20px solid $siteCol-water;
	}
	padding: 50px 0;
	#top & {
		padding: 0;
	}
	@include maxW('max-mbl'){
		padding: 20px 0;
		border: {
			top: 30px solid $siteCol-water;
			bottom: 30px solid $siteCol-water;
		}
	}
	section{
		padding-bottom: 20px;
		#top & {
			padding-bottom: 0;
		}
	}
}

.sph{
	@include maxW('max-mbl'){
		display: none;
	}
}

.pch{
	@include minW('min-mbl'){
		display: none;
	}
}


.roundBox{
	border: 5px solid $siteCol-water;
	padding: 30px;
	border-radius: 20px;
	margin-bottom: 30px;
	box-shadow: 5px 5px 10px rgba(0,0,0,.1);
	@include maxW('max-mbl'){
		padding: 15px 20px;
		margin-bottom: 1.5em;
		box-shadow: none;
		border: 3px solid $siteCol-water;
	}
}

.detailBox{
	li{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 10px;
		border-bottom: 1px solid $siteCol-water;
		margin-bottom: 10px;
		p{
			font-size: 1.3rem;
			&:first-child{
				line-height: 1.4;
				text-align: center;
				width: 20%;
				font-weight: 700;
			}
			&:last-child{
				line-height: 1.5;
				width: 75%;
			}
		}
		&:first-child{
			padding-top: 10px;
			border-top: 1px solid $siteCol-water;
		}
	}
	
}

.fsscBox{
	text-align: right;
	margin-top: -20px;
	margin-bottom: 15px;
	font-size: 1.3rem;
	@include maxW('max-mbl'){
		font-size: 1.2rem;
		margin-bottom: 15px;
	}
}


/* flex styles
   ========================================================================== */

@mixin flxBasic($flx:flex, $jc:space-between, $ai:flex-start , $flxw:wrap) {
	display: $flx;
	justify-content: $jc;
	align-items: $ai;
	flex-wrap: $flxw;
}

.flx_half{
	@include flxBasic($ai:stretch);
	>*{
		width: calc(50% - 15px);
		@include maxW('max-tblt'){
			width: 100%;
		}
	}
}

.flx_half_img{
	@include flxBasic();
	>img{
		width: 50%;
		@include maxW('max-mbl'){
			width: 100%;
		}
	}
	>div{
		width: 47%;
		@include maxW('max-mbl'){
			width: 100%;
			margin-top: 20px;
		}
	}
}

.flx_col3{
	@include flxBasic($ai:stretch);
	>div{
		width: calc((100% - 60px)/3);
		@include maxW('max-mbl'){
			width: 100%;
			margin-bottom: 20px;
			>img{
				width: 100%;
			}
		}
	}
	&.breakTblt{
		>div{
			@include maxW('max-tblt'){
				width: 100%;
				margin-bottom: 20px;
				>img{
					width: 100%;
				}
			}
		}
	}
	&.breakTri{
		>div{
			@include maxW('max-mbl'){
				width: 50%;
				margin-bottom: 0;
				>img{
					width: 100%;
				}
				&:nth-child(2){
					width: 100%;
					order: -1;
					text-align: center;
					>img{
						width: 50%;
					}
				}
			}
		}
	}
}

.flx_right{
	@include flxBasic();
	>*:not(img){
		width: 70%;
		@include maxW('max-mbl'){
			width: 100%;
		}
	}
	>img{
		width: 25.77%;
		@include maxW('max-mbl'){
			width: 250px;
			margin: 20px auto 0;
		}
	}
	.roundBox &>img{
		width: 27.779%;
		@include maxW('max-mbl'){
			width: 250px;
			margin: 20px auto 0;
		}
	}
}

/* text styles
   ========================================================================== */

.tit_st1{
	font:{
		weight: 900;
		size: 3.5rem;
		style: italic;
	}
	display: flex;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	width: 480px;
	max-width: 100%;
	margin: 20px auto 60px;
	@include maxW('max-mbl'){
		font-size: 2.8rem;
		margin-bottom: 50px;
	}
	&::before,&::after{
		content: "";
		display: block;
		height: 2px;
		width: 50%;
		background: $siteCol-black;
	}
	&::before{
		margin-right: 30px;
	}
	&::after{
		margin-left: 30px;
	}
}

.tit_st2{
	font:{
		weight: 700;
		size: 2.5rem;
		style: italic;
	}
	color: $siteCol-blue;
	padding: 6px 10px 6px 15px;
	background: #f5f5f5;
	border-left: 10px solid $siteCol-blue;
	margin: 30px 0;
	@include maxW('max-mbl'){
		font-size: 2.0rem;
		box-shadow: 2px 2px 5px rgba(0,0,0,.2);
		padding: 10px;
		margin-left: -10px;
		width: calc(100% + 30px);
	}
	@include txtFrame-white2x;
}

.tit_st3{
	font:{
		weight: 700;
		size: 2.0rem;
	}
	color: $siteCol-red;
	padding-bottom: 5px;
	border-bottom: 1px solid $siteCol-red;
	margin-bottom: 20px;
	@include maxW('max-mbl'){
		font-size: 1.5rem;
		margin-bottom: 10px;
	}
	* + &{
		margin-top: 30px;
	}
}

.tit_st4{
	font:{
		weight: 900;
		size: 2.0rem;
	}
	margin-bottom: 10px;
	@include maxW('max-mbl'){
		font-size: 1.8rem;
	}
}

.tit_st5{
	font:{
		weight: 700;
		size: 1.8rem;
	}
	line-height: 1.6;
	margin-bottom: 10px;
	@include maxW('max-mbl'){
		font-size: 1.5rem;
	}
}


p,li,a,dt,dd{
	font-size: 1.5rem;
	line-height: 1.8;
	@include maxW('max-mbl'){
		font-size: 1.2rem;
		line-height: 1.5;
	}
}

.inb{
	display: inline-block;
}

.tac{
	text-align: center;
}

.red{
	color: $siteCol-red;
}

.black{
	color: $siteCol-black;
	border-color: $siteCol-black;
}


/* slider styles
   ========================================================================== */
@import "main-top";

/* slider styles
   ========================================================================== */
@import "main-slider";

/* products styles
   ========================================================================== */
@import "main-products";

/* history styles
   ========================================================================== */
@import "main-history";

/* access styles
   ========================================================================== */
@import "main-access";

/* company styles
   ========================================================================== */
@import "main-company";

/* factory styles
   ========================================================================== */
@import "main-factory";


/* General styles
   ========================================================================== */
@import "btn";

@import "extend";

/* Main header styles
   ========================================================================== */
@import "main-header";

/* Main footer styles
   ========================================================================== */
@import "main-footer";

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
@import "utility";

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@import "print";
