#company{
	.cmp {
		
		@include maxW('max-mbl') {
			
		}
		@include minW('min-mbl') {
			
		}
	}
	
	.brochureBox{
		width: 500px;
		max-width: 100%;
		margin: auto;
	}
	
	.cmp-ov-lst{
		&_itm{
			border-radius: 20px;
			border: 3px solid $siteCol-water;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			margin-bottom: 20px;
			@include maxW('max-mbl') {
				margin-bottom: 15px;
			}
		}
		&_trm{
			width: 220px;
			background: $siteCol-water;
			@include centerCenter;
			@include txtFrame-white1x;
			@include maxW('max-mbl') {
				width: 100%;
				padding: 5px;
			}
		}
		&_dsc{
			width: calc(100% - 220px);
			padding: 20px;
			@include vertCenter;
			@include maxW('max-mbl') {
				width: 100%;
				padding: 8px 20px;
			}
		}
		.tit_st5{
			margin-bottom: 0;
		}
	}
	
	.graphBox{
		padding-top: 20px;
	}
	
	img[src*="company_ceo"]{
		order: 1;
		@include maxW('max-mbl') {
			order: 0;
		}
	}
}

