// Base styles: opinionated defaults
// =============================================================================
html {
	color: $siteCol-black;
}

body {
	// min-width: 1000px;
	@include maxW('max-mbl') { // mobile size = L //
		min-width: 320px;
	}
}

::-moz-selection {
	@include selectionStyle;
}
::selection {
	@include selectionStyle;
}