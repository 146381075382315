// General button styles
// =============================================================================
.btn {
	width: 100%;
	margin: 0 auto;
	color: $siteCol-white;
	line-height: 1.5;
	background-color: $siteCol-black;
	display: block;
	&_lnk,
	&_wrp {
		width: 100%;
		height: 100%;
		padding: 1em;
		color: currentColor;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}