#factory{
	
	.factory_btn{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 60px;
		@include maxW('max-mbl'){
			justify-content: space-between;
			margin-bottom: 40px;
		}
		a{
			@include overTrans();
			font:{
				weight: 900;
				size: 1.8rem;
			}
			display: block;
			width: 200px;
			max-width: 23%;
			border: 3px solid $siteCol-water;
			border-radius: 30px;
			text-align: center;
			padding: 8px 0;
			box-shadow: 1px 1px 5px rgba(0,0,0,.1);
			@include maxW('max-mbl'){
				width: 49%;
				max-width: none;
				font-size: 1.3rem;
				&:nth-child(n+3){
					margin-top: 10px;
				}
			}
			&:not(:first-child){
				margin-left: 2%;
				@include maxW('max-mbl'){
					margin-left: 0;
				}
			}
			&.active{
				border: 3px solid $siteCol-blue;
				background: $siteCol-blue;
				color: $siteCol-white;
			}
			&.active{
				pointer-events: none;
			}
			&:hover{
				@include minW('min-mbl'){
					border: 3px solid $siteCol-blue;
					background: $siteCol-blue;
					color: $siteCol-white;
				}
			}
		}
	}
	
/* about
   ========================================================================== */
	
	#about{

	}
	
/* concept
   ========================================================================== */

	#concept{
		.fivesList{
			margin-top: 30px;
			>li{
				margin: 5px 0;
/*
				@include flxBasic();
				>span:first-child{
					width: 200px;
				}
				>span:last-child{
					width: calc(100% - 210px);
				}
*/
			}
		}
		.fivesBox{
			max-width: 100%;
			margin: 40px auto;
			text-align: center;
			@include maxW('max-mbl'){
				margin: 40px auto 0;
			}
			.flx_fives{
				margin-bottom: 20px;
				@include flxBasic($ai:center);
				>img{
					width: 57.732%;
				}
				>div{
					width: 38%;
				}
				@include maxW('max-tblt'){
					margin-bottom: 0;
					>img{
						width: 100%;
						max-width: 560px;
						display: block;
						margin: 20px auto;
					}
					>div{
						width: 90%;
						max-width: 560px;
						margin: 20px auto;
						@include flxBasic($ai:stretch);
						>div{
							width: 49%;
							&:first-child{
								width: 100%;
							}
						}
					}
				}
			}
			.fivesTxtBox{
				>div{
					border-radius: 5px;
					margin-bottom: 20px;
					background: $siteCol-lightgrey;
					overflow: hidden;
					h6{
						color: $siteCol-white;
						font-size: 1.5rem;
						line-height: 1.8;
						font-weight: 900;
					}
					p{
						font-size: 1.3rem;
						text-align: left;
						padding: 10px;
						line-height: 1.4;
					}
					@include maxW('max-mbl'){
						margin-bottom: 15px;
						h6{
							font-size: 1.3rem;
						}
						p{
							font-size: 1.2rem;
						}
					}
				}
				>div:nth-child(1){
					border: 2px solid $siteCol-gari;
					h6{
						background: $siteCol-gari;
					}
				}
				>div:nth-child(2){
					border: 2px solid $siteCol-gatun;
					h6{
						background: $siteCol-gatun;
					}
				}
				>div:nth-child(3){
					border: 2px solid $siteCol-black;
					h6{
						background: $siteCol-black;
					}
				}
				>div:nth-child(4){
					border: 2px solid $siteCol-sof;
					h6{
						background: $siteCol-sof;
					}
				}
				>div:nth-child(5){
					border: 2px solid $siteCol-shigure;
					h6{
						background: $siteCol-shigure;
					}
				}
			}
		}
		.caseBox{
			margin-top: 30px;
		}
		.effortBox>div{
			h5{
				margin-top: 10px;
			}
			@include maxW('max-tblt'){
				h5{
					margin-top: 0;
				}
				@include flxBasic();
				>img{
					width: 233px;
					max-width: 48%;
				}
				>div{
					width: calc(100% - 253px);
					min-width: 48%;
				}
			}
			@include maxW('max-mbl'){
				>img{
					width: 100%;
					max-width: none;
				}
				>div{
					width: 100%;
					min-width: none;
					margin-top: 10px;
				}
			}
		}
		.showBox{
			margin-top: 30px;
		}
	}
	
/* safety
   ========================================================================== */
	
	#safety{
		.flowBox{
			width: 700px;
			max-width: 100%;
			margin: 50px auto 0;
		}
	}
	
/* environment
   ========================================================================== */

	#environment{
		section:first-of-type{
			margin-bottom: 30px;
		}
	}

}