// Main header styles
// =============================================================================
header{
	
}

header>.mainMenu{
	background: $siteCol-white;
	box-shadow: 0 0 20px rgba(0,0,0,.2);
	position: relative;
	z-index: 1000;
	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 110px;
		@include maxW('max-mbl'){
			height: 60px;
		}
	}
	.main_logo{
		width: 120px;
		@include maxW('max-mbl'){
			width: 90px;
		}
	}
	.main_logo+div{
		width: calc(100% - 120px);
	}
	a{
		@include overTrans();
	}
	.iconArea{
		display: flex;
		justify-content: flex-end;
		height: 28px;
		a{
			margin-left: 8px;
		}
		a:hover{
			@include minW('min-mbl'){
				opacity: .4;
			}
		}
		.icon{
			width: 28px;
			@include maxW('max-mbl'){
				display: none;
			}
		}
		.lang_switch{
			@include centerCenter;
			font-size: 1.5rem;
			width: 90px;
			color: $siteCol-white;
			background: #bbb;
			border-radius: 5px;
			@include maxW('max-mbl'){
				font-size: 1.3rem;
				width: 70px;
			}
		}
		.spMenuBtn{
			@include minW('min-mbl'){
				display: none;
			}
			position: relative;
/*
			display: flex;
			flex-direction: column;
			justify-content: space-between;
*/
			width: 32px;
			height: 100%;
			>div{
				@include overTrans();
				background: #777;
				width: 100%;
				height: 5px;
				position: absolute;
				left: 0;
				&:first-child{
					top: 1px;
				}
				&:nth-child(2){
					top: 50%;
					transform: translate(0,-50%);
				}
				&:last-child{
					bottom: 1px;
				}
			}
		}
		.spMenuBtn.active{
			>div{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				&:first-child{
					transform: translate(-50%,-50%) rotate(45deg);
				}
				&:nth-child(2){
					left: 20px;
					opacity: 0;
				}
				&:last-child{
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
	nav{
		font-weight: 900;
		margin-top: 15px;
		a:hover,a.active{
			color: $siteCol-red;
		}
		a.active{
			pointer-events: none;
		}
		ul{
			display: flex;
			justify-content: flex-end;
		}
		li{
			
			margin-left: calc(1em + 3px);
			a{
				font-size: 2.0rem;
				@include maxW('max-tblt'){
					font-size: 1.5rem;
				}
			}
		}
		@include maxW('max-mbl'){
			display: none;
		}
	}
}

header>.spMenu{
	display: none;
	position: absolute;
	width: 100%;
	box-shadow: 0 0 20px rgba(0,0,0,.5);
	z-index: 500;
	@include minW('min-mbl'){
		display: none !important;
	}
	background: rgba(241,241,241,.98);
	padding: 20px 0;
	nav{
		text-align: center;
		font-weight: 900;
		li{
			margin-top: 20px;
		}
		a{
			font-size: 2.2rem;
		}
		a.active{
			color: $siteCol-red;
			pointer-events: none;
		}
	}
	.iconArea{
		display: flex;
		justify-content: center;
		margin-top: 30px;
		a:last-child{
			margin-left: 8px;
		}
		.icon{
			width: 40px;
		}
	}
}
