#top{
	
	/** RESET AND LAYOUT
	===================================*/
	
	.bx-wrapper {
		position: relative;
// 		margin-bottom: 60px;
		padding: 0;
		*zoom: 1;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		border-bottom: 20px solid $siteCol-water;
		overflow: hidden;
		@include maxW('max-mbl'){
			border-bottom: 30px solid $siteCol-water;
		}
	}
	
	.bx-wrapper img{
		max-width: 100%;
	}
		
	.bx-wrapper li>div{
		position: relative;
		max-height: 600px;
		height: 35vw;
		margin: auto;
		@include maxW('max-mbl'){
			height: auto;
		}
	}

	@include minW('min-mbl'){
		.bx-wrapper li img {
			position: absolute;
			width: 100%;
			height: auto;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
	@media only screen and (min-width: 2000px) {
		.bx-wrapper li>div{
			max-width: 2000px;
		}
		.bx-wrapper li>div:before,
		.bx-wrapper li>div:after{
			content: "";
			position: absolute;
			width: 100px;
			height: 100%;
			background: #000;
			top: 0;
			z-index: 100;
		}
		.bx-wrapper li>div:before{
			left: 0;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
			background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
		}
		.bx-wrapper li>div:after{
			right: 0;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
		}
	}
	
	.bxslider {
		margin: 0;
		padding: 0;
	}
	
	ul.bxslider {
		list-style: none;
	}
	
	.bx-viewport {
		/*fix other elements on the page moving (on Chrome)*/
		-webkit-transform: translatez(0);
	}
	
	
	/** THEME
	===================================*/
	
	.bx-wrapper {
		background: #fff;
	}
	
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		position: absolute;
		bottom: -30px;
		width: 100%;
	}
	
	
	/* LOADER */
	
	.bx-wrapper .bx-loading {
		min-height: 50px;
		background: url('images/bx_loader.gif') center center no-repeat #ffffff;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2000;
	}
	
	
	/* PAGER */
	
	.bx-wrapper .bx-pager {
		text-align: center;
		font-size: .85em;
		font-family: Arial;
		font-weight: bold;
		color: #666;
		padding-top: 20px;
		position: absolute;
		bottom: 20px;
	}
	
	.bx-wrapper .bx-pager.bx-default-pager a {
		background: #fff;
		text-indent: -9999px;
		display: block;
		width: 18px;
		height: 18px;
		margin: 0 7px;
		outline: 0;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
		border-radius: 50%;
		border: 2px solid #aaa;
		@include maxW('max-mbl'){
			width: 14px;
			height: 14px;
			margin: 0 5px;
		}
	}
	
	.bx-wrapper .bx-pager.bx-default-pager a:hover{
		@include minW('min-mbl'){
			background: #fa2500;
			border: 2px solid #fff;
		}
	}
	.bx-wrapper .bx-pager.bx-default-pager a.active,
	.bx-wrapper .bx-pager.bx-default-pager a:focus {
		background: #fa2500;
		border: 2px solid #fff;
	}
	
	.bx-wrapper .bx-pager-item,
	.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
		display: inline-block;
		vertical-align: bottom;
		*zoom: 1;
		*display: inline;
	}
	
	.bx-wrapper .bx-pager-item {
		font-size: 0;
		line-height: 0;
	}
	
	
	/* DIRECTION CONTROLS (NEXT / PREV) */
	
	.bx-wrapper .bx-prev {
		left: 10px;
		background: url('images/controls.png') no-repeat 0 -32px;
	}
	
	.bx-wrapper .bx-prev:hover,
	.bx-wrapper .bx-prev:focus {
		background-position: 0 0;
	}
	
	.bx-wrapper .bx-next {
		right: 10px;
		background: url('images/controls.png') no-repeat -43px -32px;
	}
	
	.bx-wrapper .bx-next:hover,
	.bx-wrapper .bx-next:focus {
		background-position: -43px 0;
	}
	
	.bx-wrapper .bx-controls-direction a {
		position: absolute;
		top: 50%;
		margin-top: -16px;
		outline: 0;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
		z-index: 9999;
	}
	
	.bx-wrapper .bx-controls-direction a.disabled {
		display: none;
	}
	
	
	/* AUTO CONTROLS (START / STOP) */
	
	.bx-wrapper .bx-controls-auto {
		text-align: center;
	}
	
	.bx-wrapper .bx-controls-auto .bx-start {
		display: block;
		text-indent: -9999px;
		width: 10px;
		height: 11px;
		outline: 0;
		background: url('images/controls.png') -86px -11px no-repeat;
		margin: 0 3px;
	}
	
	.bx-wrapper .bx-controls-auto .bx-start:hover,
	.bx-wrapper .bx-controls-auto .bx-start.active,
	.bx-wrapper .bx-controls-auto .bx-start:focus {
		background-position: -86px 0;
	}
	
	.bx-wrapper .bx-controls-auto .bx-stop {
		display: block;
		text-indent: -9999px;
		width: 9px;
		height: 11px;
		outline: 0;
		background: url('images/controls.png') -86px -44px no-repeat;
		margin: 0 3px;
	}
	
	.bx-wrapper .bx-controls-auto .bx-stop:hover,
	.bx-wrapper .bx-controls-auto .bx-stop.active,
	.bx-wrapper .bx-controls-auto .bx-stop:focus {
		background-position: -86px -33px;
	}
	
	
	/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
	
	.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
		text-align: left;
		width: 80%;
	}
	
	.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
		right: 0;
		width: 35px;
	}
	
	
	/* IMAGE CAPTIONS */
	
	.bx-wrapper .bx-caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #666;
		background: rgba(80, 80, 80, 0.75);
		width: 100%;
	}
	
	.bx-wrapper .bx-caption span {
		color: #fff;
		font-family: Arial;
		display: block;
		font-size: .85em;
		padding: 10px;
	}

}