// Utility classes
// =============================================================================
/*
 * text classes
 */
.utl-fwn {
	font-weight: $fwn;
}
.utl-fwb {
	font-weight: $fwb;
}

$fwNsnsJpArr: (
	'thin': $fwNsnsJp-thin,
	'light': $fwNsnsJp-light,
	'regular': $fwNsnsJp-regular,
	'medium': $fwNsnsJp-medium,
	'bold': $fwNsnsJp-bold,
	'black': $fwNsnsJp-black
);
@each $fwName, $fwNum in $fwNsnsJpArr {
	.utl-fwnsnsjp-#{$fwName} {
		font-weight: $fwNum;
	}
}

.utl-tal {
	text-align: left;
}
.utl-tar {
	text-align: right;
}
.utl-tac {
	text-align: center;
}
.utl-taj {
	text-align: justify;
}

/*
 * content display classes
 */
.utl-di {
	display: inline;
}
.utl-db {
	display: block;
}
.utl-dib {
	display: inline-block;
}
.utl-dflx {
	// display: -webkit-flex;
	display: flex;
}
.utl-diflx {
	// display: -webkit-inline-flex;
	display: inline-flex;
}

/*
 * position classes
 */
.utl-rltv {
	position: relative;
}
.utl-absl {
	position: absolute;
}
.utl-fxd {
	position: fixed;
}

/*
 * link disable
 */
.utl-nolnk {
	pointer-events: none;
}

/*
 * anchor block
 */
a {
	.utl-anch-blk &,
	&.utl-anch-blk {
		display: block;
	}
}

/*
 * text break
 */
.utl-br {
	display: inline-block;
}

/*
 * text indent
 */
.utl-indent {
	text-indent: 1em;
}
.utl-neg-indent {
	margin-left: 1em;
	text-indent: -1em;
}

/*
 * image replacement
 */
.utl-ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

/*
 * mouse over action
 */
.utl-ovr {
	@include minW('min-mbl') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
	}
}

/*
 * add pointer effect
 */
.utl-ptr {
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

/*
 * change svg color
 */
.utl-chg-svg-col {
	fill: currentColor;
}

/*
 * clear fix
 */
.utl-cf {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
	visibility: hidden;
}

/*
 * hide visually and from screen readers
 */
.utl-hdn {
	display: none !important;
}
.utl-mbl-hdn {
	@include maxW('max-mbl') { // mobile size = L //
		display: none;
	}
}
.utl-pc-hdn {
	@include minW('min-mbl') { // desktop size = S //
		display: none;
	}
}
.utl-tblt-hdn {
	@include minW-to-maxW('min-mbl', 'tblt-l') { // tablet size = S - L //
		display: none;
	}
}

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn {
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
}

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc {
	@extend .utl-visually-hdn;
	&:active,
	&:focus {
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		clip: auto;
	}
}
