@charset "UTF-8";
/*
$siteCol-f-wht: #fff;
$siteCol-k-blk: #000;
*/
/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
html {
	color: #333;
}

@media screen and (max-width: 670px) {
	body {
		min-width: 320px;
	}
}

::-moz-selection {
	text-shadow: none;
	background-color: #b3d4fc;
}

::selection {
	text-shadow: none;
	background-color: #b3d4fc;
}

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */
body {
	background: #fff;
}

.inner {
	width: 970px;
	max-width: calc(100% - 40px);
	margin: auto;
}

main {
	background: #fff;
	border-top: 20px solid #c5e4f5;
	border-bottom: 20px solid #c5e4f5;
	padding: 50px 0;
}

#top main {
	padding: 0;
}

@media screen and (max-width: 670px) {
	main {
		padding: 20px 0;
		border-top: 30px solid #c5e4f5;
		border-bottom: 30px solid #c5e4f5;
	}
}

main section {
	padding-bottom: 20px;
}

#top main section {
	padding-bottom: 0;
}

@media screen and (max-width: 670px) {
	.sph {
		display: none;
	}
}

@media screen and (min-width: 671px) {
	.pch {
		display: none;
	}
}

.roundBox {
	border: 5px solid #c5e4f5;
	padding: 30px;
	border-radius: 20px;
	margin-bottom: 30px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 670px) {
	.roundBox {
		padding: 15px 20px;
		margin-bottom: 1.5em;
		box-shadow: none;
		border: 3px solid #c5e4f5;
	}
}

.detailBox li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 10px;
	border-bottom: 1px solid #c5e4f5;
	margin-bottom: 10px;
}

.detailBox li p {
	font-size: 1.3rem;
}

.detailBox li p:first-child {
	line-height: 1.4;
	text-align: center;
	width: 20%;
	font-weight: 700;
}

.detailBox li p:last-child {
	line-height: 1.5;
	width: 75%;
}

.detailBox li:first-child {
	padding-top: 10px;
	border-top: 1px solid #c5e4f5;
}

.fsscBox {
	text-align: right;
	margin-top: -20px;
	margin-bottom: 15px;
	font-size: 1.3rem;
}

@media screen and (max-width: 670px) {
	.fsscBox {
		font-size: 1.2rem;
		margin-bottom: 15px;
	}
}

/* flex styles
   ========================================================================== */
.flx_half {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
}

.flx_half > * {
	width: calc(50% - 15px);
}

@media screen and (max-width: 860px) {
	.flx_half > * {
		width: 100%;
	}
}

.flx_half_img {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

.flx_half_img > img {
	width: 50%;
}

@media screen and (max-width: 670px) {
	.flx_half_img > img {
		width: 100%;
	}
}

.flx_half_img > div {
	width: 47%;
}

@media screen and (max-width: 670px) {
	.flx_half_img > div {
		width: 100%;
		margin-top: 20px;
	}
}

.flx_col3 {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
}

.flx_col3 > div {
	width: calc((100% - 60px)/3);
}

@media screen and (max-width: 670px) {
	.flx_col3 > div {
		width: 100%;
		margin-bottom: 20px;
	}
	.flx_col3 > div > img {
		width: 100%;
	}
}

@media screen and (max-width: 860px) {
	.flx_col3.breakTblt > div {
		width: 100%;
		margin-bottom: 20px;
	}
	.flx_col3.breakTblt > div > img {
		width: 100%;
	}
}

@media screen and (max-width: 670px) {
	.flx_col3.breakTri > div {
		width: 50%;
		margin-bottom: 0;
	}
	.flx_col3.breakTri > div > img {
		width: 100%;
	}
	.flx_col3.breakTri > div:nth-child(2) {
		width: 100%;
		order: -1;
		text-align: center;
	}
	.flx_col3.breakTri > div:nth-child(2) > img {
		width: 50%;
	}
}

.flx_right {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

.flx_right > *:not(img) {
	width: 70%;
}

@media screen and (max-width: 670px) {
	.flx_right > *:not(img) {
		width: 100%;
	}
}

.flx_right > img {
	width: 25.77%;
}

@media screen and (max-width: 670px) {
	.flx_right > img {
		width: 250px;
		margin: 20px auto 0;
	}
}

.roundBox .flx_right > img {
	width: 27.779%;
}

@media screen and (max-width: 670px) {
	.roundBox .flx_right > img {
		width: 250px;
		margin: 20px auto 0;
	}
}

/* text styles
   ========================================================================== */
.tit_st1 {
	font-weight: 900;
	font-size: 3.5rem;
	font-style: italic;
	display: flex;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	width: 480px;
	max-width: 100%;
	margin: 20px auto 60px;
}

@media screen and (max-width: 670px) {
	.tit_st1 {
		font-size: 2.8rem;
		margin-bottom: 50px;
	}
}

.tit_st1::before, .tit_st1::after {
	content: "";
	display: block;
	height: 2px;
	width: 50%;
	background: #333;
}

.tit_st1::before {
	margin-right: 30px;
}

.tit_st1::after {
	margin-left: 30px;
}

.tit_st2 {
	font-weight: 700;
	font-size: 2.5rem;
	font-style: italic;
	color: #00aef2;
	padding: 6px 10px 6px 15px;
	background: #f5f5f5;
	border-left: 10px solid #00aef2;
	margin: 30px 0;
	text-shadow: #fff 2px 0px 0px, #fff -2px 0px 0px, #fff 0px -2px 0px, #fff 0px 2px 0px, #fff 2px 2px 0px, #fff -2px 2px 0px, #fff 2px -2px 0px, #fff -2px -2px 0px, #fff 1px 2px 0px, #fff -1px 2px 0px, #fff 1px -2px 0px, #fff -1px -2px 0px, #fff 2px 1px 0px, #fff -2px 1px 0px, #fff 2px -1px 0px, #fff -2px -1px 0px, #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
}

@media screen and (max-width: 670px) {
	.tit_st2 {
		font-size: 2.0rem;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
		padding: 10px;
		margin-left: -10px;
		width: calc(100% + 30px);
	}
}

.tit_st3 {
	font-weight: 700;
	font-size: 2.0rem;
	color: #e8340c;
	padding-bottom: 5px;
	border-bottom: 1px solid #e8340c;
	margin-bottom: 20px;
}

@media screen and (max-width: 670px) {
	.tit_st3 {
		font-size: 1.5rem;
		margin-bottom: 10px;
	}
}

* + .tit_st3 {
	margin-top: 30px;
}

.tit_st4 {
	font-weight: 900;
	font-size: 2.0rem;
	margin-bottom: 10px;
}

@media screen and (max-width: 670px) {
	.tit_st4 {
		font-size: 1.8rem;
	}
}

.tit_st5 {
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 1.6;
	margin-bottom: 10px;
}

@media screen and (max-width: 670px) {
	.tit_st5 {
		font-size: 1.5rem;
	}
}

p, li, a, dt, dd {
	font-size: 1.5rem;
	line-height: 1.8;
}

@media screen and (max-width: 670px) {
	p, li, a, dt, dd {
		font-size: 1.2rem;
		line-height: 1.5;
	}
}

.inb {
	display: inline-block;
}

.tac {
	text-align: center;
}

.red {
	color: #e8340c;
}

.black {
	color: #333;
	border-color: #333;
}

/* slider styles
   ========================================================================== */
#top .topProArea {
	overflow: hidden;
	padding: 50px 0;
}

@media screen and (max-width: 670px) {
	#top .topProArea {
		padding: 20px 0;
	}
}

#top .topProArea p {
	font-size: 1.3rem;
}

@media screen and (max-width: 670px) {
	#top .topProArea p {
		font-size: 1.1rem;
	}
}

#top .topProArea .topProBox1 > .topDescBox::after {
	background: #00aef2;
}

#top .topProArea .topProBox2 > .topDescBox::after {
	background: #f08204;
}

#top .topProArea .topProBox3 > .topDescBox::after {
	background: #221816;
}

#top .topProArea .topProBox4 > .topDescBox::after {
	background: #1e2777;
}

@media screen and (min-width: 861px) {
	#top .topProArea .stantBox {
		width: 890px;
		max-width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		flex-wrap: wrap;
		transform: rotate(-10deg);
	}
	#top .topProArea .stantBox > .topImgBox {
		width: 66%;
		position: relative;
		z-index: 300;
	}
	#top .topProArea .stantBox > .topDescBox {
		width: calc(34% + 20px);
		margin: 25px 0;
		z-index: 200;
		position: relative;
	}
	#top .topProArea .stantBox > .topDescBox > div {
		background: #f5f5f5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		padding: 30px 40px;
	}
	#top .topProArea .stantBox > .topDescBox::after {
		content: "";
		position: absolute;
		top: 7px;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
	#top .topProArea .stantBox .topDescBox > div > div {
		transform: rotate(10deg);
		padding-top: 10px;
	}
	#top .topProArea .topProBox1 {
		margin-top: 100px;
		margin-left: -50px;
		margin-right: auto;
	}
	#top .topProArea .topProBox1 > .topDescBox {
		margin-left: -20px;
	}
	#top .topProArea .topProBox1 > .topDescBox > div {
		border-radius: 0 0 30%/50% 0;
	}
	#top .topProArea .topProBox1 > .topDescBox::after {
		left: 4px;
		border-radius: 0 100px 100px 0;
	}
	#top .topProArea .topProBox2 {
		margin-top: 70px;
		margin-left: auto;
		margin-right: -50px;
	}
	#top .topProArea .topProBox2 > .topImgBox {
		order: 1;
	}
	#top .topProArea .topProBox2 > .topDescBox {
		margin-right: -20px;
	}
	#top .topProArea .topProBox2 > .topDescBox > div {
		border-radius: 0 0 0 30%/50%;
	}
	#top .topProArea .topProBox2 > .topDescBox::after {
		left: -4px;
		border-radius: 100px 0 0 100px;
	}
	#top .topProArea .circleBox {
		position: relative;
		width: 320px;
		max-width: 35%;
	}
	#top .topProArea .circleBox > .topImgBox {
		position: absolute;
		z-index: 300;
	}
	#top .topProArea .circleBox > .topDescBox {
		width: 100%;
	}
	#top .topProArea .circleBox > .topDescBox::before {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding-top: 100%;
		border-radius: 50%;
		background: #f5f5f5;
		z-index: 100;
	}
	#top .topProArea .circleBox > .topDescBox > div {
		position: relative;
		z-index: 200;
	}
	#top .topProArea .circleBox > .topDescBox::after {
		content: "";
		position: absolute;
		z-index: 10;
		width: calc(100% + 10px);
		padding-top: calc(100% + 10px);
		border-radius: 50%;
		transform: translateX(-50%);
	}
	#top .topProArea .topProBox3 {
		margin-top: 70px;
		margin-left: auto;
	}
	#top .topProArea .topProBox3 > .topImgBox {
		width: 150%;
		max-width: 150%;
		right: 15%;
		top: 100%;
		transform: rotate(-10deg);
	}
	#top .topProArea .topProBox3 > .topDescBox::before {
		top: 0;
	}
	#top .topProArea .topProBox3 > .topDescBox > div {
		padding: 20% 45px 50px;
	}
	#top .topProArea .topProBox3 > .topDescBox::after {
		top: -7px;
		left: calc(50% + 4px);
	}
	#top .topProArea .topProBox4 {
		margin-top: 120px;
		margin-right: auto;
		margin-bottom: 100px;
	}
	#top .topProArea .topProBox4 > .topImgBox {
		width: 85%;
		max-width: 85%;
		right: -15%;
		bottom: 90%;
	}
	#top .topProArea .topProBox4 > .topDescBox::before {
		bottom: 60%;
		transform: translate(-50%, 50%);
	}
	#top .topProArea .topProBox4 > .topDescBox > div {
		padding: 0 45px 20px;
	}
	#top .topProArea .topProBox4 > .topDescBox::after {
		bottom: calc(60% - 7px);
		left: calc(50% - 4px);
		transform: translate(-50%, 50%);
	}
}

@media screen and (max-width: 860px) {
	#top .topProArea .stantBox, #top .topProArea .circleBox {
		width: 100%;
		transform: none;
	}
	#top .topProArea .stantBox > .topImgBox, #top .topProArea .circleBox > .topImgBox {
		width: 486px;
		max-width: 90%;
		z-index: 300;
		position: relative;
		display: block;
	}
	#top .topProArea .stantBox > .topDescBox, #top .topProArea .circleBox > .topDescBox {
		width: 80%;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-top: -2vw;
	}
	#top .topProArea .stantBox > .topDescBox > div, #top .topProArea .circleBox > .topDescBox > div {
		position: relative;
		background: #f5f5f5;
		padding: 30px;
		z-index: 100;
	}
	#top .topProArea .stantBox > .topDescBox::after, #top .topProArea .circleBox > .topDescBox::after {
		content: "";
		position: absolute;
		top: 5px;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
	#top .topProArea .stantBox .topDescBox > div > div, #top .topProArea .circleBox .topDescBox > div > div {
		transform: none;
	}
	#top .topProArea .topProBox1, #top .topProArea .topProBox3 {
		margin: 10vw auto;
	}
	#top .topProArea .topProBox1 > .topImgBox, #top .topProArea .topProBox3 > .topImgBox {
		margin-left: 0;
		margin-right: auto;
	}
	#top .topProArea .topProBox1 > .topDescBox, #top .topProArea .topProBox3 > .topDescBox {
		margin-left: -30px;
		margin-right: auto;
	}
	#top .topProArea .topProBox1 > .topDescBox::after, #top .topProArea .topProBox3 > .topDescBox::after {
		left: 5px;
		border-radius: 0 0 22.5px 0;
	}
	#top .topProArea .topProBox1 > .topDescBox > div, #top .topProArea .topProBox3 > .topDescBox > div {
		border-radius: 0 0 20px 0;
	}
	#top .topProArea .topProBox2, #top .topProArea .topProBox4 {
		margin: 10vw auto;
	}
	#top .topProArea .topProBox2 > .topImgBox, #top .topProArea .topProBox4 > .topImgBox {
		order: 0;
		margin-left: auto;
		margin-right: 0;
	}
	#top .topProArea .topProBox2 > .topDescBox, #top .topProArea .topProBox4 > .topDescBox {
		margin-left: auto;
		margin-right: -30px;
	}
	#top .topProArea .topProBox2 > .topDescBox::after, #top .topProArea .topProBox4 > .topDescBox::after {
		left: -5px;
		border-radius: 0 0 0 22.5px;
	}
	#top .topProArea .topProBox2 > .topDescBox > div, #top .topProArea .topProBox4 > .topDescBox > div {
		border-radius: 0 0 0 20px;
	}
	#top .topProArea .topProBox1 {
		margin-top: 15vw;
	}
	#top .topProArea .topProBox4 > .topImgBox {
		width: 274px;
		max-width: 50%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 860px) and (max-width: 670px) {
	#top .topProArea .stantBox > .topDescBox, #top .topProArea .circleBox > .topDescBox {
		width: 70%;
	}
	#top .topProArea .stantBox > .topDescBox > div, #top .topProArea .circleBox > .topDescBox > div {
		padding: 20px;
	}
	#top .topProArea .topProBox1 > .topImgBox, #top .topProArea .topProBox3 > .topImgBox {
		margin-left: -40px;
	}
	#top .topProArea .topProBox1 > .topDescBox, #top .topProArea .topProBox3 > .topDescBox {
		margin-left: -20px;
	}
	#top .topProArea .topProBox2 > .topImgBox {
		margin-left: auto;
		margin-right: -40px;
	}
}

@media only screen and (max-width: 860px) and (max-width: 670px) and (max-width: 500px) {
	#top .topProArea .topProBox2 > .topImgBox {
		margin-left: calc(15% + 40px);
	}
}

@media screen and (max-width: 860px) and (max-width: 670px) {
	#top .topProArea .topProBox2 > .topDescBox {
		margin-right: -20px;
	}
	#top .topProArea .topProBox4 > .topImgBox {
		margin-right: 4vw;
	}
	#top .topProArea .topProBox4 > .topDescBox {
		margin-right: -20px;
		margin-top: -5vw;
	}
}

#top .topProArea .topProBtnBox {
	text-align: center;
}

#top .topProArea .topProBtnBox a {
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
	position: relative;
	display: inline-block;
	background: #e8340c;
	color: #fff;
	width: 400px;
	height: 70px;
	margin: 70px auto;
	font-size: 2.0rem;
	font-weight: 900;
	border-radius: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#top .topProArea .topProBtnBox a::after {
	content: "＞";
	position: absolute;
	right: 35px;
	top: 50%;
	transform: translateY(-50%);
}

@media screen and (max-width: 670px) {
	#top .topProArea .topProBtnBox a {
		font-size: 1.6rem;
		width: 80%;
		margin: 50px auto 30px;
		height: 60px;
	}
}

@media screen and (min-width: 671px) {
	#top .topProArea .topProBtnBox a:hover {
		transform: scale(1.05);
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	}
	#top .topProArea .topProBtnBox a:hover::after {
		right: 30px;
	}
}

#top .topBtnArea {
	background: #c5e4f5;
	padding: 70px 0 60px;
}

@media screen and (max-width: 860px) {
	#top .topBtnArea {
		padding: 30px 0 0;
	}
}

#top .topBtnArea .topBtnBox {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

#top .topBtnArea .topBtnBox a {
	width: calc((100% - 70px)/3);
	padding-top: calc((100% - 70px)/3);
	border: 5px solid #fff;
	position: relative;
	overflow: hidden;
}

@media screen and (max-width: 860px) {
	#top .topBtnArea .topBtnBox a {
		width: 100%;
		border: 4px solid #fff;
	}
	#top .topBtnArea .topBtnBox a:not(:last-child) {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 670px) {
	#top .topBtnArea .topBtnBox a {
		padding-top: 40%;
		border: 3px solid #fff;
	}
}

#top .topBtnArea .topBtnBox a img {
	position: absolute;
	width: auto;
	max-width: none;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;
}

@media screen and (max-width: 860px) {
	#top .topBtnArea .topBtnBox a img {
		width: 100%;
		height: auto;
	}
}

#top .topBtnArea .topBtnBox a h4 {
	position: absolute;
	width: 100%;
	text-align: center;
	text-shadow: 0 0 7px #000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	z-index: 300;
	font-size: 2.5rem;
	font-weight: 900;
	font-style: italic;
}

@media screen and (max-width: 670px) {
	#top .topBtnArea .topBtnBox a h4 {
		font-size: 2.0rem;
	}
}

#top .topBtnArea .topBtnBox a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 200;
}

#top .topBtnArea .topBtnBox a::after {
	content: "";
	position: absolute;
	display: block;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	background: url(../img/top_btn_arrow.png);
	background-size: 100%;
	width: 21px;
	height: 21px;
	z-index: 300;
}

@media screen and (max-width: 670px) {
	#top .topBtnArea .topBtnBox a::after {
		width: 16px;
		height: 16px;
	}
}

#top .topBtnArea .topBtnBox a > *, #top .topBtnArea .topBtnBox a::after, #top .topBtnArea .topBtnBox a::before {
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (min-width: 671px) {
	#top .topBtnArea .topBtnBox a:hover img {
		transform: translate(-50%, -50%) scale(1.05);
	}
	#top .topBtnArea .topBtnBox a:hover::before {
		opacity: .4;
	}
}

/* slider styles
   ========================================================================== */
#top {
	/** RESET AND LAYOUT
	===================================*/
	/** THEME
	===================================*/
	/* LOADER */
	/* PAGER */
	/* DIRECTION CONTROLS (NEXT / PREV) */
	/* AUTO CONTROLS (START / STOP) */
	/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
	/* IMAGE CAPTIONS */
}

#top .bx-wrapper {
	position: relative;
	padding: 0;
	*zoom: 1;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	border-bottom: 20px solid #c5e4f5;
	overflow: hidden;
}

@media screen and (max-width: 670px) {
	#top .bx-wrapper {
		border-bottom: 30px solid #c5e4f5;
	}
}

#top .bx-wrapper img {
	max-width: 100%;
}

#top .bx-wrapper li > div {
	position: relative;
	max-height: 600px;
	height: 35vw;
	margin: auto;
}

@media screen and (max-width: 670px) {
	#top .bx-wrapper li > div {
		height: auto;
	}
}

@media screen and (min-width: 671px) {
	#top .bx-wrapper li img {
		position: absolute;
		width: 100%;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media only screen and (min-width: 2000px) {
	#top .bx-wrapper li > div {
		max-width: 2000px;
	}
	#top .bx-wrapper li > div:before,
	#top .bx-wrapper li > div:after {
		content: "";
		position: absolute;
		width: 100px;
		height: 100%;
		background: #000;
		top: 0;
		z-index: 100;
	}
	#top .bx-wrapper li > div:before {
		left: 0;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
		background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
		/* IE6-9 */
	}
	#top .bx-wrapper li > div:after {
		right: 0;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
		/* IE6-9 */
	}
}

#top .bxslider {
	margin: 0;
	padding: 0;
}

#top ul.bxslider {
	list-style: none;
}

#top .bx-viewport {
	/*fix other elements on the page moving (on Chrome)*/
	-webkit-transform: translatez(0);
}

#top .bx-wrapper {
	background: #fff;
}

#top .bx-wrapper .bx-pager,
#top .bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
}

#top .bx-wrapper .bx-loading {
	min-height: 50px;
	background: url("images/bx_loader.gif") center center no-repeat #ffffff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

#top .bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	padding-top: 20px;
	position: absolute;
	bottom: 20px;
}

#top .bx-wrapper .bx-pager.bx-default-pager a {
	background: #fff;
	text-indent: -9999px;
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 7px;
	outline: 0;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 50%;
	border: 2px solid #aaa;
}

@media screen and (max-width: 670px) {
	#top .bx-wrapper .bx-pager.bx-default-pager a {
		width: 14px;
		height: 14px;
		margin: 0 5px;
	}
}

@media screen and (min-width: 671px) {
	#top .bx-wrapper .bx-pager.bx-default-pager a:hover {
		background: #fa2500;
		border: 2px solid #fff;
	}
}

#top .bx-wrapper .bx-pager.bx-default-pager a.active,
#top .bx-wrapper .bx-pager.bx-default-pager a:focus {
	background: #fa2500;
	border: 2px solid #fff;
}

#top .bx-wrapper .bx-pager-item,
#top .bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	vertical-align: bottom;
	*zoom: 1;
	*display: inline;
}

#top .bx-wrapper .bx-pager-item {
	font-size: 0;
	line-height: 0;
}

#top .bx-wrapper .bx-prev {
	left: 10px;
	background: url("images/controls.png") no-repeat 0 -32px;
}

#top .bx-wrapper .bx-prev:hover,
#top .bx-wrapper .bx-prev:focus {
	background-position: 0 0;
}

#top .bx-wrapper .bx-next {
	right: 10px;
	background: url("images/controls.png") no-repeat -43px -32px;
}

#top .bx-wrapper .bx-next:hover,
#top .bx-wrapper .bx-next:focus {
	background-position: -43px 0;
}

#top .bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	width: 32px;
	height: 32px;
	text-indent: -9999px;
	z-index: 9999;
}

#top .bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

#top .bx-wrapper .bx-controls-auto {
	text-align: center;
}

#top .bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	background: url("images/controls.png") -86px -11px no-repeat;
	margin: 0 3px;
}

#top .bx-wrapper .bx-controls-auto .bx-start:hover,
#top .bx-wrapper .bx-controls-auto .bx-start.active,
#top .bx-wrapper .bx-controls-auto .bx-start:focus {
	background-position: -86px 0;
}

#top .bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	background: url("images/controls.png") -86px -44px no-repeat;
	margin: 0 3px;
}

#top .bx-wrapper .bx-controls-auto .bx-stop:hover,
#top .bx-wrapper .bx-controls-auto .bx-stop.active,
#top .bx-wrapper .bx-controls-auto .bx-stop:focus {
	background-position: -86px -33px;
}

#top .bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

#top .bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

#top .bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

#top .bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}

/* products styles
   ========================================================================== */
#products .flx_products {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

#products .flx_products > div {
	width: 53%;
	min-width: calc(100% - 200px);
}

#products .flx_products > img {
	width: 45%;
	max-width: 180px;
}

@media screen and (max-width: 670px) {
	#products .flx_products {
		align-items: center;
	}
	#products .flx_products > div {
		width: 58%;
	}
	#products .flx_products > img {
		width: 40%;
	}
}

/* history styles
   ========================================================================== */
#history section:nth-of-type(1) .imgBox {
	text-align: center;
	margin-top: 30px;
}

#history section:nth-of-type(1) .imgBox > img {
	width: 370px;
	max-width: 70%;
}

#history section:nth-of-type(1) p {
	line-height: 2;
}

#history section:nth-of-type(2) .flx_profile {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

#history section:nth-of-type(2) .imgBox {
	width: 360px;
	text-align: center;
}

#history section:nth-of-type(2) .imgBox > img {
	width: 360px;
}

@media screen and (max-width: 860px) {
	#history section:nth-of-type(2) .imgBox {
		width: 80%;
		margin: 0 auto 30px;
	}
}

#history section:nth-of-type(2) .profileBox {
	width: calc((100% - 360px)/2);
}

@media screen and (max-width: 860px) {
	#history section:nth-of-type(2) .profileBox {
		width: 50%;
	}
}

#history section:nth-of-type(2) .profileBox ul li {
	margin-bottom: 10px;
}

#history section:nth-of-type(2) .profileBox ul p {
	font-size: 1.3rem;
}

@media screen and (max-width: 670px) {
	#history section:nth-of-type(2) .profileBox ul p {
		font-size: 1.1rem;
	}
}

#history section:nth-of-type(2) .profileBox .profile_h {
	color: #fff;
	display: inline-block;
	text-align: center;
	width: 130px;
	font-weight: 700;
	border-radius: 5px;
	margin-bottom: 10px;
}

@media screen and (max-width: 860px) {
	#history section:nth-of-type(2) .profileBox .profile_h {
		width: 50%;
		min-width: 130px;
	}
}

#history section:nth-of-type(2) .profileBox:nth-child(2) {
	order: -1;
	padding-right: 20px;
}

@media screen and (max-width: 860px) {
	#history section:nth-of-type(2) .profileBox:nth-child(2) {
		order: 0;
		text-align: center;
	}
}

#history section:nth-of-type(2) .profileBox:nth-child(2) .profile_h {
	background: #00a0ff;
}

#history section:nth-of-type(2) .profileBox:last-child {
	text-align: right;
	padding-left: 20px;
}

@media screen and (max-width: 860px) {
	#history section:nth-of-type(2) .profileBox:last-child {
		text-align: center;
		border-left: 1px solid #bbb;
	}
}

#history section:nth-of-type(2) .profileBox:last-child .profile_h {
	background: #ff82b3;
}

/* access styles
   ========================================================================== */
.acs-flx-box {
	font-size: 1.3rem;
}

@media screen and (max-width: 670px) {
	.acs-flx-box {
		margin-top: 1.5em;
	}
}

@media screen and (min-width: 671px) {
	.acs-flx-box {
		margin-top: 2em;
		display: flex;
	}
	.acs-flx-box_inr {
		width: 100%;
		max-width: 400px;
		margin-right: 30px;
		flex-shrink: 0;
	}
}

.acs-sct {
	font-size: 1.3rem;
	line-height: 1.5;
	border: solid #c5e4f5;
}

.acs-sct.-head-office {
	text-shadow: #fff 2px 0px 0px, #fff -2px 0px 0px, #fff 0px -2px 0px, #fff 0px 2px 0px, #fff 2px 2px 0px, #fff -2px 2px 0px, #fff 2px -2px 0px, #fff -2px -2px 0px, #fff 1px 2px 0px, #fff -1px 2px 0px, #fff 1px -2px 0px, #fff -1px -2px 0px, #fff 2px 1px 0px, #fff -2px 1px 0px, #fff 2px -1px 0px, #fff -2px -1px 0px, #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
	background-color: #c5e4f5;
}

.acs-sct_fct-name {
	font-weight: 900;
}

@media screen and (max-width: 670px) {
	.acs-sct {
		padding: 1em;
		border-width: 3px;
		border-radius: 10px;
	}
	.acs-sct + .acs-sct {
		margin-top: 1.5em;
	}
	.acs-sct_fct-name {
		font-size: 1.23077em;
	}
	.-head-office .acs-sct_fct-name {
		font-size: 1.53846em;
	}
	.acs-sct_thmb {
		margin: 1.5em auto 0;
	}
}

@media screen and (min-width: 671px) {
	.acs-sct {
		padding: 2em;
		border-width: 5px;
		border-radius: 20px;
	}
	.acs-sct.-head-office {
		display: flex;
		justify-content: space-between;
	}
	.acs-sct + .acs-sct {
		margin-top: 2em;
	}
	.acs-sct_fct-name {
		font-size: 1.53846em;
	}
	.-head-office .acs-sct_fct-name {
		font-size: 1.92308em;
	}
	.acs-sct_thmb {
		width: 100%;
		max-width: 370px;
		margin-left: 2em;
		align-self: center;
	}
}

.acs-lst {
	margin-top: 1em;
	text-shadow: #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
}

.acs-lst_itm:nth-child(n+2) {
	margin-top: 0.5em;
}

.acs-lst_trm {
	font-weight: 900;
}

.gmap {
	width: 100%;
	font-size: 1.3rem;
	border-radius: 10px;
}

@media screen and (max-width: 670px) {
	.gmap {
		height: 60vw;
		min-height: 250px;
		max-height: 320px;
		margin-top: 1.5em;
	}
}

@media screen and (min-width: 671px) {
	.gmap {
		width: 100%;
	}
}

/* company styles
   ========================================================================== */
#company .brochureBox {
	width: 500px;
	max-width: 100%;
	margin: auto;
}

#company .cmp-ov-lst_itm {
	border-radius: 20px;
	border: 3px solid #c5e4f5;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	margin-bottom: 20px;
}

@media screen and (max-width: 670px) {
	#company .cmp-ov-lst_itm {
		margin-bottom: 15px;
	}
}

#company .cmp-ov-lst_trm {
	width: 220px;
	background: #c5e4f5;
	display: flex;
	justify-content: center;
	align-items: center;
	text-shadow: #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
}

@media screen and (max-width: 670px) {
	#company .cmp-ov-lst_trm {
		width: 100%;
		padding: 5px;
	}
}

#company .cmp-ov-lst_dsc {
	width: calc(100% - 220px);
	padding: 20px;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 670px) {
	#company .cmp-ov-lst_dsc {
		width: 100%;
		padding: 8px 20px;
	}
}

#company .cmp-ov-lst .tit_st5 {
	margin-bottom: 0;
}

#company .graphBox {
	padding-top: 20px;
}

#company img[src*="company_ceo"] {
	order: 1;
}

@media screen and (max-width: 670px) {
	#company img[src*="company_ceo"] {
		order: 0;
	}
}

/* factory styles
   ========================================================================== */
#factory {
	/* about
   ========================================================================== */
	/* concept
   ========================================================================== */
	/* safety
   ========================================================================== */
	/* environment
   ========================================================================== */
}

#factory .factory_btn {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 60px;
}

@media screen and (max-width: 670px) {
	#factory .factory_btn {
		justify-content: space-between;
		margin-bottom: 40px;
	}
}

#factory .factory_btn a {
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
	font-weight: 900;
	font-size: 1.8rem;
	display: block;
	width: 200px;
	max-width: 23%;
	border: 3px solid #c5e4f5;
	border-radius: 30px;
	text-align: center;
	padding: 8px 0;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 670px) {
	#factory .factory_btn a {
		width: 49%;
		max-width: none;
		font-size: 1.3rem;
	}
	#factory .factory_btn a:nth-child(n+3) {
		margin-top: 10px;
	}
}

#factory .factory_btn a:not(:first-child) {
	margin-left: 2%;
}

@media screen and (max-width: 670px) {
	#factory .factory_btn a:not(:first-child) {
		margin-left: 0;
	}
}

#factory .factory_btn a.active {
	border: 3px solid #00aef2;
	background: #00aef2;
	color: #fff;
}

#factory .factory_btn a.active {
	pointer-events: none;
}

@media screen and (min-width: 671px) {
	#factory .factory_btn a:hover {
		border: 3px solid #00aef2;
		background: #00aef2;
		color: #fff;
	}
}

#factory #concept .fivesList {
	margin-top: 30px;
}

#factory #concept .fivesList > li {
	margin: 5px 0;
	/*
				@include flxBasic();
				>span:first-child{
					width: 200px;
				}
				>span:last-child{
					width: calc(100% - 210px);
				}
*/
}

#factory #concept .fivesBox {
	max-width: 100%;
	margin: 40px auto;
	text-align: center;
}

@media screen and (max-width: 670px) {
	#factory #concept .fivesBox {
		margin: 40px auto 0;
	}
}

#factory #concept .fivesBox .flx_fives {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

#factory #concept .fivesBox .flx_fives > img {
	width: 57.732%;
}

#factory #concept .fivesBox .flx_fives > div {
	width: 38%;
}

@media screen and (max-width: 860px) {
	#factory #concept .fivesBox .flx_fives {
		margin-bottom: 0;
	}
	#factory #concept .fivesBox .flx_fives > img {
		width: 100%;
		max-width: 560px;
		display: block;
		margin: 20px auto;
	}
	#factory #concept .fivesBox .flx_fives > div {
		width: 90%;
		max-width: 560px;
		margin: 20px auto;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
	}
	#factory #concept .fivesBox .flx_fives > div > div {
		width: 49%;
	}
	#factory #concept .fivesBox .flx_fives > div > div:first-child {
		width: 100%;
	}
}

#factory #concept .fivesBox .fivesTxtBox > div {
	border-radius: 5px;
	margin-bottom: 20px;
	background: #f5f5f5;
	overflow: hidden;
}

#factory #concept .fivesBox .fivesTxtBox > div h6 {
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.8;
	font-weight: 900;
}

#factory #concept .fivesBox .fivesTxtBox > div p {
	font-size: 1.3rem;
	text-align: left;
	padding: 10px;
	line-height: 1.4;
}

@media screen and (max-width: 670px) {
	#factory #concept .fivesBox .fivesTxtBox > div {
		margin-bottom: 15px;
	}
	#factory #concept .fivesBox .fivesTxtBox > div h6 {
		font-size: 1.3rem;
	}
	#factory #concept .fivesBox .fivesTxtBox > div p {
		font-size: 1.2rem;
	}
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(1) {
	border: 2px solid #00aef2;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(1) h6 {
	background: #00aef2;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(2) {
	border: 2px solid #f08204;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(2) h6 {
	background: #f08204;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(3) {
	border: 2px solid #333;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(3) h6 {
	background: #333;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(4) {
	border: 2px solid #1e2777;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(4) h6 {
	background: #1e2777;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(5) {
	border: 2px solid #e35a84;
}

#factory #concept .fivesBox .fivesTxtBox > div:nth-child(5) h6 {
	background: #e35a84;
}

#factory #concept .caseBox {
	margin-top: 30px;
}

#factory #concept .effortBox > div h5 {
	margin-top: 10px;
}

@media screen and (max-width: 860px) {
	#factory #concept .effortBox > div {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	#factory #concept .effortBox > div h5 {
		margin-top: 0;
	}
	#factory #concept .effortBox > div > img {
		width: 233px;
		max-width: 48%;
	}
	#factory #concept .effortBox > div > div {
		width: calc(100% - 253px);
		min-width: 48%;
	}
}

@media screen and (max-width: 670px) {
	#factory #concept .effortBox > div > img {
		width: 100%;
		max-width: none;
	}
	#factory #concept .effortBox > div > div {
		width: 100%;
		min-width: none;
		margin-top: 10px;
	}
}

#factory #concept .showBox {
	margin-top: 30px;
}

#factory #safety .flowBox {
	width: 700px;
	max-width: 100%;
	margin: 50px auto 0;
}

#factory #environment section:first-of-type {
	margin-bottom: 30px;
}

/* General styles
   ========================================================================== */
.btn {
	width: 100%;
	margin: 0 auto;
	color: #fff;
	line-height: 1.5;
	background-color: #333;
	display: block;
}

.btn_lnk, .btn_wrp {
	width: 100%;
	height: 100%;
	padding: 1em;
	color: currentColor;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Main header styles
   ========================================================================== */
header > .mainMenu {
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1000;
}

header > .mainMenu .inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 110px;
}

@media screen and (max-width: 670px) {
	header > .mainMenu .inner {
		height: 60px;
	}
}

header > .mainMenu .main_logo {
	width: 120px;
}

@media screen and (max-width: 670px) {
	header > .mainMenu .main_logo {
		width: 90px;
	}
}

header > .mainMenu .main_logo + div {
	width: calc(100% - 120px);
}

header > .mainMenu a {
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

header > .mainMenu .iconArea {
	display: flex;
	justify-content: flex-end;
	height: 28px;
}

header > .mainMenu .iconArea a {
	margin-left: 8px;
}

@media screen and (min-width: 671px) {
	header > .mainMenu .iconArea a:hover {
		opacity: .4;
	}
}

header > .mainMenu .iconArea .icon {
	width: 28px;
}

@media screen and (max-width: 670px) {
	header > .mainMenu .iconArea .icon {
		display: none;
	}
}

header > .mainMenu .iconArea .lang_switch {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	width: 90px;
	color: #fff;
	background: #bbb;
	border-radius: 5px;
}

@media screen and (max-width: 670px) {
	header > .mainMenu .iconArea .lang_switch {
		font-size: 1.3rem;
		width: 70px;
	}
}

header > .mainMenu .iconArea .spMenuBtn {
	position: relative;
	/*
			display: flex;
			flex-direction: column;
			justify-content: space-between;
*/
	width: 32px;
	height: 100%;
}

@media screen and (min-width: 671px) {
	header > .mainMenu .iconArea .spMenuBtn {
		display: none;
	}
}

header > .mainMenu .iconArea .spMenuBtn > div {
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
	background: #777;
	width: 100%;
	height: 5px;
	position: absolute;
	left: 0;
}

header > .mainMenu .iconArea .spMenuBtn > div:first-child {
	top: 1px;
}

header > .mainMenu .iconArea .spMenuBtn > div:nth-child(2) {
	top: 50%;
	transform: translate(0, -50%);
}

header > .mainMenu .iconArea .spMenuBtn > div:last-child {
	bottom: 1px;
}

header > .mainMenu .iconArea .spMenuBtn.active > div {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

header > .mainMenu .iconArea .spMenuBtn.active > div:first-child {
	transform: translate(-50%, -50%) rotate(45deg);
}

header > .mainMenu .iconArea .spMenuBtn.active > div:nth-child(2) {
	left: 20px;
	opacity: 0;
}

header > .mainMenu .iconArea .spMenuBtn.active > div:last-child {
	transform: translate(-50%, -50%) rotate(-45deg);
}

header > .mainMenu nav {
	font-weight: 900;
	margin-top: 15px;
}

header > .mainMenu nav a:hover, header > .mainMenu nav a.active {
	color: #e8340c;
}

header > .mainMenu nav a.active {
	pointer-events: none;
}

header > .mainMenu nav ul {
	display: flex;
	justify-content: flex-end;
}

header > .mainMenu nav li {
	margin-left: calc(1em + 3px);
}

header > .mainMenu nav li a {
	font-size: 2.0rem;
}

@media screen and (max-width: 860px) {
	header > .mainMenu nav li a {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 670px) {
	header > .mainMenu nav {
		display: none;
	}
}

header > .spMenu {
	display: none;
	position: absolute;
	width: 100%;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	z-index: 500;
	background: rgba(241, 241, 241, 0.98);
	padding: 20px 0;
}

@media screen and (min-width: 671px) {
	header > .spMenu {
		display: none !important;
	}
}

header > .spMenu nav {
	text-align: center;
	font-weight: 900;
}

header > .spMenu nav li {
	margin-top: 20px;
}

header > .spMenu nav a {
	font-size: 2.2rem;
}

header > .spMenu nav a.active {
	color: #e8340c;
	pointer-events: none;
}

header > .spMenu .iconArea {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

header > .spMenu .iconArea a:last-child {
	margin-left: 8px;
}

header > .spMenu .iconArea .icon {
	width: 40px;
}

/* Main footer styles
   ========================================================================== */
footer {
	padding: 20px 0;
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 670px) {
	footer {
		text-align: center;
	}
}

footer > .inner {
	position: relative;
}

@media screen and (max-width: 670px) {
	footer > .inner {
		max-width: 100%;
	}
}

footer > .inner .iconArea {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

@media screen and (max-width: 670px) {
	footer > .inner .iconArea {
		justify-content: center;
		position: relative;
		top: 0;
		transform: none;
		margin-bottom: 30px;
	}
}

footer > .inner .iconArea a:last-child {
	margin-left: 10px;
}

footer > .inner .iconArea .icon {
	width: 50px;
}

footer > .inner nav {
	margin-bottom: 30px;
}

footer > .inner nav ul {
	display: flex;
	flex-wrap: wrap;
}

footer > .inner nav li {
	line-height: 1.4;
}

footer > .inner nav li:not(:last-child) {
	padding-right: 20px;
	margin-right: 20px;
	border-right: 1px solid #bbb;
}

@media screen and (max-width: 670px) {
	footer > .inner nav li:not(:last-child) {
		border-right: none;
		margin-right: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 670px) {
	footer > .inner nav li:last-child {
		width: 100%;
	}
}

@media screen and (max-width: 670px) {
	footer > .inner nav li {
		width: 50%;
		padding: 15px 0;
		border-bottom: 1px solid #bbb;
	}
	footer > .inner nav li:nth-child(2n+1) {
		border-right: 1px solid #bbb;
	}
	footer > .inner nav li:not(:nth-child(n+3)) {
		border-top: 1px solid #bbb;
	}
}

footer > .inner nav a {
	line-height: 1.4;
}

@media screen and (max-width: 670px) {
	footer > .inner nav a {
		display: block;
	}
}

footer > .inner a {
	font-size: 1.3rem;
	transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (max-width: 670px) {
	footer > .inner a {
		font-size: 1.1rem;
	}
}

footer > .inner a.active {
	opacity: .4;
	pointer-events: none;
}

@media screen and (min-width: 671px) {
	footer > .inner a:hover {
		opacity: .4;
	}
}

footer > .inner p {
	font-size: 1.3rem;
}

@media screen and (max-width: 670px) {
	footer > .inner p {
		font-size: 1.1rem;
	}
}

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
/*
 * text classes
 */
.utl-fwn {
	font-weight: 400;
}

.utl-fwb {
	font-weight: 600;
}

.utl-fwnsnsjp-thin {
	font-weight: 100;
}

.utl-fwnsnsjp-light {
	font-weight: 300;
}

.utl-fwnsnsjp-regular {
	font-weight: 400;
}

.utl-fwnsnsjp-medium {
	font-weight: 500;
}

.utl-fwnsnsjp-bold {
	font-weight: 700;
}

.utl-fwnsnsjp-black {
	font-weight: 900;
}

.utl-tal {
	text-align: left;
}

.utl-tar {
	text-align: right;
}

.utl-tac {
	text-align: center;
}

.utl-taj {
	text-align: justify;
}

/*
 * content display classes
 */
.utl-di {
	display: inline;
}

.utl-db {
	display: block;
}

.utl-dib {
	display: inline-block;
}

.utl-dflx {
	display: flex;
}

.utl-diflx {
	display: inline-flex;
}

/*
 * position classes
 */
.utl-rltv {
	position: relative;
}

.utl-absl {
	position: absolute;
}

.utl-fxd {
	position: fixed;
}

/*
 * link disable
 */
.utl-nolnk {
	pointer-events: none;
}

/*
 * anchor block
 */
.utl-anch-blk a, a.utl-anch-blk {
	display: block;
}

/*
 * text break
 */
.utl-br {
	display: inline-block;
}

/*
 * text indent
 */
.utl-indent {
	text-indent: 1em;
}

.utl-neg-indent {
	margin-left: 1em;
	text-indent: -1em;
}

/*
 * image replacement
 */
.utl-ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

/*
 * mouse over action
 */
@media screen and (min-width: 671px) {
	.utl-ovr {
		transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1);
	}
	.utl-ovr:hover {
		opacity: 0.4;
	}
}

/*
 * add pointer effect
 */
.utl-ptr {
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.utl-scroll-bar-hdn::-webkit-scrollbar {
	display: none;
}

/*
 * change svg color
 */
.utl-chg-svg-col {
	fill: currentColor;
}

/*
 * clear fix
 */
.utl-cf::before, .utl-cf::after {
	content: '';
	display: table;
}

.utl-cf::after {
	clear: both;
}

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
	visibility: hidden;
}

/*
 * hide visually and from screen readers
 */
.utl-hdn {
	display: none !important;
}

@media screen and (max-width: 670px) {
	.utl-mbl-hdn {
		display: none;
	}
}

@media screen and (min-width: 671px) {
	.utl-pc-hdn {
		display: none;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.utl-tblt-hdn {
		display: none;
	}
}

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn, .utl-visually-hdn-foc {
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
}

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc:active, .utl-visually-hdn-foc:focus {
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	clip: auto;
}

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@media print {
	*, *::before, *::after, *::first-letter, *::first-line {
		color: #000 !important;
		/* Black prints faster */
		text-shadow: none !important;
		box-shadow: none !important;
		background: transparent !important;
	}
	a, a:visited {
		text-decoration: underline;
	}
	a[href]::after {
		content: ' (" attr(href) ")';
	}
	a[href^="#"]::after, a[href^="javascript:"]::after {
		content: '';
	}
	abbr[title]::after {
		content: ' (" attr(title) ")';
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: solid 1px #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr, img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}
	h2, h3 {
		page-break-after: avoid;
	}
}
