.acs-flx-box {
	font-size: $fz13;
	@include maxW('max-mbl') {
		margin-top: 1.5em;
	}
	@include minW('min-mbl') {
		margin-top: 2em;
		display: flex;
		&_inr {
			width: 100%;
			max-width: 400px;
			margin-right: 30px;
			flex-shrink: 0;
		}
	}
}

.acs-sct {
	font-size: $fz13;
	line-height: 1.5;
	border: solid $siteCol-water;
	&.-head-office {
		@include txtFrame-white2x;
		background-color: $siteCol-water;
	}
	&_fct-name {
		font-weight: 900;
	}
	@include maxW('max-mbl') {
		padding: 1em;
		border: {
			width: 3px;
			radius: 10px;
		}
		& + & {
			margin-top: 1.5em;
		}
		&_fct-name {
			font-size: 1.6 / 1.3 + 0em;
			.-head-office & {
				font-size: 2 / 1.3 + 0em;
			}
		}
		&_thmb {
			margin: 1.5em auto 0;
		}
	}
	@include minW('min-mbl') {
		padding: 2em;
		border: {
			width: 5px;
			radius: 20px;
		}
		&.-head-office {
			display: flex;
			justify-content: space-between;
		}
		& + & {
			margin-top: 2em;
		}
		&_fct-name {
			font-size: 2 / 1.3 + 0em;
			.-head-office & {
				font-size: 2.5 / 1.3 + 0em;
			}
		}
		&_thmb {
			width: 100%;
			max-width: 370px;
			margin-left: 2em;
			align-self: center;
		}
	}
}

.acs-lst {
	margin-top: 1em;
	@include txtFrame-white1x;
	&_itm {
		&:nth-child(n+2) {
			margin-top: 0.5em;
		}
	}
	&_trm{
		font-weight: 900;
	}
	@include maxW('max-mbl') {
		
	}
	@include minW('min-mbl') {
		
	}
}

.gmap {
	width: 100%;
	font-size: $fz13;
	border-radius: 10px;
	@include maxW('max-mbl') {
		height: 60vw;
		min-height: 250px;
		max-height: 320px;
		margin-top: 1.5em;
	}
	@include minW('min-mbl') {
		width: 100%;
	}
}
