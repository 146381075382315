#history{
	section:nth-of-type(1){
		.imgBox{
			text-align: center;
			margin-top: 30px;
			>img{
				width: 370px;
				max-width: 70%;
			}
		}
		p{
			line-height: 2;
		}
	}
	section:nth-of-type(2){
		.flx_profile{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		.imgBox{
			width: 360px;
			text-align: center;
			>img{
				width: 360px;
			}
			@include maxW('max-tblt'){
				width: 80%;
				margin: 0 auto 30px;
			}
		}
		.profileBox{
			width: calc((100% - 360px)/2);
			@include maxW('max-tblt'){
				width: 50%;
			}
			ul{
				li{
					margin-bottom: 10px;
				}
				p{
					font-size: 1.3rem;
					@include maxW('max-mbl'){
						font-size: 1.1rem;
					}
				}
			}
			.profile_h{
				color: $siteCol-white;
				display: inline-block;
				text-align: center;
				width: 130px;
				font-weight: 700;
				border-radius: 5px;
				margin-bottom: 10px;
				@include maxW('max-tblt'){
					width: 50%;
					min-width: 130px;
				}
			}
			&:nth-child(2){
				order: -1;
				padding-right: 20px;
				@include maxW('max-tblt'){
					order: 0;
					text-align: center;
				}
				.profile_h{
					background: #00a0ff;
				}
			}
			&:last-child{
				text-align: right;
				padding-left: 20px;
				@include maxW('max-tblt'){
					text-align: center;
					border-left: 1px solid $siteCol-grey;
				}
				.profile_h{
					background: #ff82b3;
				}
			}
		}
	}
}