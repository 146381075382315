// Main footer styles
// =============================================================================
footer {
	padding: 20px 0;
	background: $siteCol-white;
	box-shadow: 0 0 20px rgba(0,0,0,.1);
	@include maxW('max-mbl'){
		text-align: center;
	}
}
footer>.inner{
	position: relative;
	@include maxW('max-mbl'){
		max-width: 100%;
	}
	.iconArea{
		display: flex;
		justify-content: flex-end;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		@include maxW('max-mbl'){
			justify-content: center;
			position: relative;
			top: 0;
			transform: none;
			margin-bottom: 30px;
		}
		a:last-child{
			margin-left: 10px;
		}
		.icon{
			width: 50px;
		}
	}
	nav{
		margin-bottom: 30px;
		ul{
			display: flex;
			flex-wrap: wrap;
		}
		li{
			line-height: 1.4;
			&:not(:last-child){
				padding-right: 20px;
				margin-right: 20px;
				border-right: 1px solid $siteCol-grey;
				@include maxW('max-mbl'){
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
			&:last-child{ // ナビゲーションが奇数の場合
				@include maxW('max-mbl'){
					width: 100%;
				}
			}
			@include maxW('max-mbl'){
				width: 50%;
				padding: 15px 0;
				border-bottom: 1px solid $siteCol-grey;
				&:nth-child(2n+1){
					border-right: 1px solid $siteCol-grey;
				}
				&:not(:nth-child(n+3)){
					border-top: 1px solid $siteCol-grey;
				}
			}
		}
		a{
			line-height: 1.4;
			@include maxW('max-mbl'){
				display: block;
			}
		}
	}
	a{
		font-size: 1.3rem;
		transition: all 300ms $easeOutQuint;
		@include maxW('max-mbl'){
			font-size: 1.1rem;
		}
	}
	a.active{
		opacity: .4;
		pointer-events: none;
	}
	a:hover{
		@include minW('min-mbl'){
			opacity: .4;
		}
	}
	p{
		font-size: 1.3rem;
		@include maxW('max-mbl'){
			font-size: 1.1rem;
		}
	}
}
