// device size
$deviceWidthArr:(
	
	'tblt-l': 1050px,
		
	'max-mbl': 670px,
	'min-mbl': 671px,
	
	'max-tblt': 860px,
	'min-tblt': 861px,
);

// media queries
// Ex. @include minW('min-mbl') { }
@mixin minW($value) {
	@media screen and (min-width: map-get($deviceWidthArr, $value)) {
		@content;
	}
}
// Ex. @include maxW('max-mbl') { }
@mixin maxW($value) {
	@media screen and (max-width: map-get($deviceWidthArr, $value)) {
		@content;
	}
}
// Ex. @include minW-to-maxW('min-mbl', 'tblt-l') { }
@mixin minW-to-maxW($minWidth, $maxWidth) {
	@media screen and (min-width: map-get($deviceWidthArr, $minWidth)) and (max-width: map-get($deviceWidthArr, $maxWidth)) {
		@content;
	}
}

// site color
$siteCol-white: #fff;
$siteCol-black: #333;
$siteCol-grey: #bbb;
$siteCol-lightgrey: #f5f5f5;
$siteCol-red: #e8340c;
$siteCol-blue: #00aef2;
$siteCol-water: #c5e4f5;

$siteCol-gari: #00aef2;
$siteCol-gatun: #f08204;
$siteCol-blackice: #221816;
$siteCol-sof: #1e2777;
$siteCol-shigure: #e35a84;

// aligned on center
@mixin centerCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
@mixin vertCenter {
	display: flex;
	align-items: center;
}

@mixin txtFrame-white1x{
	text-shadow: 
	#fff 1px 1px 0px, #fff -1px 1px 0px,
	#fff 1px -1px 0px, #fff -1px -1px 0px;
}

@mixin txtFrame-white2x{
	text-shadow: 
	#fff 2px 0px 0px, #fff -2px 0px 0px,
	#fff 0px -2px 0px, #fff 0px 2px 0px,
	#fff 2px 2px 0px, #fff -2px 2px 0px,
	#fff 2px -2px 0px, #fff -2px -2px 0px,
	#fff 1px 2px 0px, #fff -1px 2px 0px,
	#fff 1px -2px 0px, #fff -1px -2px 0px,
	#fff 2px 1px 0px, #fff -2px 1px 0px,
	#fff 2px -1px 0px, #fff -2px -1px 0px,
	#fff 1px 1px 0px, #fff -1px 1px 0px,
	#fff 1px -1px 0px, #fff -1px -1px 0px;
}

@mixin overTrans($speed:400ms){
	transition: all $speed $easeOutQuint;
}

// btn over opacity


/*
$siteCol-f-wht: #fff;
$siteCol-k-blk: #000;
*/

// transition duration time
$overTime: 450ms;

// for selection style
// Ex. @include selectionStyle($shadowCol: #000, $bgCol: #111);
@mixin selectionStyle($shadowCol: none, $bgCol: #b3d4fc) {
	text-shadow: $shadowCol;
	background-color: $bgCol;
}

// base font size
// Ex. @include baseFontSize($fz15);
@mixin baseFontSize($value) {
	font-size: $value;
}

// font options
$ffTyp01: 'Font Awesome\ 5 Free', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$ffTyp02: 'Font Awesome\ 5 Free', 'YakuHanMP_Noto', 'Noto Serif JP', 'Hiragino Mincho ProN', 'ヒラギノ明朝 ProN W3', YuMincho, '游明朝', 'Yu Mincho', 'HG明朝E', 'MS PMincho', 'ＭＳ Ｐ明朝', serif;
$ffTyp03: 'Font Awesome\ 5 Free', 'YakuHanJP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Noto Sans Japanese', YuGothic, '游ゴシック体', 'Yu Gothic', '游ゴシック', Meiryo, 'メイリオ', Osaka, 'MS PGothic', 'ＭＳ Ｐゴシック', sans-serif;

$fz8: 0.8rem;
$fz9: 0.9rem;
$fz10: 1rem;
$fz11: 1.1rem;
$fz13: 1.3rem;
$fz15: 1.5rem;
$fz17: 1.7rem;
$fz19: 1.9rem;
$fz21: 2.1rem;
$fz23: 2.3rem;
$fz25: 2.5rem;
$fz27: 2.7rem;
$fz29: 2.9rem;
$fz31: 3.1rem;
$fz33: 3.3rem;
$fz35: 3.5rem;

$fwn: 400;
$fwb: 600;

// font weight for NotoSansJP
// Ex. font-weight: $fwNsnsJp-thin;
$fwNsnsJp-thin: 100;
$fwNsnsJp-light: 300;
$fwNsnsJp-regular: 400;
$fwNsnsJp-medium: 500;
$fwNsnsJp-bold: 700;
$fwNsnsJp-black: 900;

// font weight for NotoSerifJP
// Ex. font-weight: $fwNsrfJp-exlight;
$fwNsrfJp-exlight: 200;
$fwNsrfJp-light: 300;
$fwNsrfJp-regular: 400;
$fwNsrfJp-medium: 500;
$fwNsrfJp-smbold: 600;
$fwNsrfJp-bold: 700;
$fwNsrfJp-black: 900;

$baseFwn: $fwNsnsJp-medium;
$baseFwb: $fwNsnsJp-bold;

$baseLh: 1.5;

// for background image
// Ex. background-image: imgUrl('value.jpg');
$imgPath: '../img/';
@function imgUrl($fileName) {
	@return url(#{$imgPath}#{$fileName});
}

// for background gradient
// Ex. @include bgGradOpt($baseCol: rgba(255, 255, 255, 1), $stratCol: rgba(255, 255, 255, 1), $endCol: rgba(0, 0, 0, 1), $deg: 0deg, $stratPos: 100%, $endPos: 0%);
@mixin bgGradOpt($baseCol: rgba(255, 255, 255, 1), $stratCol: rgba(255, 255, 255, 1), $endCol: rgba(0, 0, 0, 1), $deg: 0deg, $stratPos: 100%, $endPos: 0%) {
	background: {
		color: $baseCol;
		image: linear-gradient(
			$deg,
			$endCol $endPos,
			$stratCol $stratPos
		);
	}
}

// css transition easing
// Ex. transition: opacity 600ms $easeInSine;
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// base contents width 01
// Ex. @include mainCntWidth01($maxWidth: 980px);
@mixin mainCntWidth01($maxWidth: 1000px) {
	max-width: $maxWidth;
	margin: 0 auto;
}

// base contents width 02
// Ex. @include mainCntWidth02($width: calc(100% - 24px), $maxWidth: 980px);
@mixin mainCntWidth02($width: calc(100% - 40px), $maxWidth: 1000px) {
	width: $width;
	max-width: $maxWidth;
}

// base contents width
// Ex. @include cntCenter(0px 20px);
@mixin cntCenter($value: 0 auto) {
	margin: $value;
}

@mixin txtMargin($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2)) 0;
}
@mixin txtMt($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin-top: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2));
}
@mixin txtMb($baseMargin: 0px, $inheritFz: 1em, $inheritLh: $baseLh) {
	margin-bottom: calc(#{$baseMargin} - (((#{$inheritFz} * #{$inheritLh}) - #{$inheritFz}) / 2));
}

$baseCenterPos: 50%;

$mbl-pad: 20px;
$tblt-pad: 40px;
$pc-pad: 60px;
